<template>
    <div class="container-pc">
        <div class="add-view Global-W">
            <div class="add-title f-w">
                <div>Shipping Address Setting</div>
                <div class="CURSOR" @click="handleisShowOrderAddress('Add')">Add new address</div>
            </div>
            <div class="add-list" v-loading="isorderListLoading">
                <div class="add-item" v-for="(item, index) in AddressList" :key="index">
                    <div class="add-item-view" v-if="!item.edit">
                        <div class="add-item-left">
                            <div>{{ item.receiver }}</div>
                            <div>{{ item.street }}</div>
                            <div>{{ item.detail }}</div>
                            <div>{{ item.city }} {{ item.state }} {{ item.postcode }}</div>
                        </div>
                        <div class="add-item-right">
                            <div class="CURSOR" @click="handleisShowOrderAddress('Edit', item)">Edit</div>
                            <el-popconfirm title="Are you sure you want to delete this address?" width="330"
                                @confirm="handleRemoveAddress(item)">
                                <template #reference>
                                    <div class="CURSOR">Remove</div>
                                </template>
                            </el-popconfirm>

                            <!-- <div class="CURSOR" @click="handleRemoveAddress(item)">Remove</div> -->
                            <!-- <div class="CURSOR">Set as preferred address</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-m">

        <div class="add-view-m">
            <div class="add-title f-w">
                <div>Shipping Address Setting</div>
            </div>
            <div class="add-list" v-loading="isorderListLoading">
                <div class="add-item" v-for="(item, index) in AddressList" :key="index">
                    <div class="add-item-view" v-if="!item.edit">
                        <div class="add-item-left">
                            <div>{{ item.receiver }}</div>
                            <div>{{ item.street }}</div>
                            <div>{{ item.detail }}</div>
                            <div>{{ item.city }} {{ item.state }} {{ item.postcode }}</div>
                        </div>
                        <div class="add-item-right">
                            <div class="CURSOR" @click="handleisShowOrderAddress('Edit', item)">Edit</div>
                            <el-popconfirm title="Are you sure you want to delete this address?" width="330"
                                @confirm="handleRemoveAddress(item)">
                                <template #reference>
                                    <div class="CURSOR">Remove</div>
                                </template>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>



            </div>
            <van-button class="login-button" block type="primary" color="#414141" native-type="submit"
                @click="handleisShowOrderAddress('Add')">Add new address</van-button>
        </div>

    </div>
    <OrderAddress v-if="isShowOrderAddress" :info="EditAddressInfo" @change="handleisShowOrderAddress" />

</template>



<script setup name="Address">
import { ref, onMounted } from "vue";
import requestAsync from "@/utils/request";
import USA from '@/utils/GeoJson/USA.json'
import OrderAddress from '@/components/common/OrderAddress'
import { ElMessage, ElLoading } from 'element-plus'

// import { useRoute, useRouter } from "vue-router";
// const route = useRoute()
// const router = useRouter()
const isShowOrderAddress = ref(false)
const CountryOptions = ref([{
    value: 'USA',
    label: 'US'
}])
const checked = ref('')
const AddressList = ref([])
const AddressInfo = ref({
    id: '',
    country: '', // 国家
    receiver: "z", // 姓名
    mobile: "17513066638", // 手机号
    street: "大望路", // 街道信息
    detail: '',  // 门牌号
    city: "", // 市
    state: "", // 州
    postcode: '', // 邮编
})
const EditAddressInfo = ref({})

const isShowAddressInfo = ref(false)
const isorderListLoading = ref(true)

onMounted(() => {
    GetAddress()
})

const GetAddress = async () => {
    isorderListLoading.value = true
    const data = await requestAsync({
        url: '/userAddressinfo/address',
    })
    console.log(data)
    isorderListLoading.value = false
    if (data.code == 200) {
        AddressList.value = data.data
    }
}


const SetAddressInfo = async () => {
    const data = await requestAsync({
        url: '/userAddressinfo/userAddressinfoSaveOrUpdate',
        methods: 'post',
        data: AddressInfo.value
    })
    console.log('AddressInfo', data)
}


const handleEdit = (item) => {
    item.edit = !item.edit
}

const handleisShowOrderAddress = (event, item) => {
    isShowOrderAddress.value = !isShowOrderAddress.value
    if (event == 1) {
        GetAddress()
    }
    if (event == 'Edit') {
        EditAddressInfo.value = item
    }
    if (event == 'Add') {
        EditAddressInfo.value = {}
    }
}

const handleRemoveAddress = async (item) => {
    const data = await requestAsync({
        url: '/userAddressinfo/userAddressinfoDel',
        data: {
            id: item.id
        }
    })
    if (data.code == 200) {
        ElMessage({
            showClose: true,
            message: 'Successfully deleted!',
            type: 'success',
        })
        GetAddress()
    } else {
        ElMessage.error(data.msg)
    }
    console.log('AddressInfo', data)
}





</script>


<style lang="scss" scoped>
:deep() {

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        border-color: $globalColor !important;
        background-color: $globalColor !important;
    }

    .el-checkbox__inner:hover {
        border-color: $globalColor !important;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: $globalColor !important;
    }

}

.container-pc {
    .add-view {}

    .add-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #f5f6f7;
    }

    .add-list {
        padding: 20px 0;
        min-height: 500px;

        .myinfo-item-item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .myinfo-item-label {
                width: 150px;
                height: 100%;
                font-size: 15px;
            }

            .login-input {
                width: 200px;
            }
        }

        .add-item {

            padding: 20px;
            border-left: 8px solid grey;
            margin-bottom: 20px;
            background-color: #f5f6f7;
            font-size: 15px;

            .add-item-view {
                display: flex;
                justify-content: space-between;

            }

            .add-item-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                line-height: 30px;
            }

            .add-item-right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                div {
                    height: 30px;
                    line-height: 30px;
                }
            }

            .add-item-phone {
                margin: 10px 0;
            }


            .myinfo-item-item {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .myinfo-item-label {
                    width: 200px;
                    height: 100%;
                    font-size: 15px;
                }

                .login-input {
                    width: 200px;
                }
            }


        }
    }
}

.container-m {
    .add-view-m {
        font-size: .2rem;
        width: 100%;
        padding: 0 .3rem;

        .add-title {
            width: 100%;
            height: .5rem;
            line-height: .5rem;
            font-size: .3rem;
            ;
            text-align: center;
        }

        .add-list {
            padding: .3rem 0;

            min-height: 6rem;

            .add-item {
                width: 100%;
                border-bottom: 1px solid #f1f1f1;
                padding: .2rem 0;

                .add-item-view {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 100%;
                }

                .add-item-left {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;

                    .add-item-phone {
                        margin: .1rem 0;
                    }
                }

                .add-item-right {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 1rem;
                    height: .5rem;
                    line-height: .5rem;
                    border-left: 1px solid#f1f1f1;
                    text-align: center;
                }

                .login-m-input-item {
                    display: flex;
                    width: 100%;
                    height: .6rem;
                    margin: .2rem auto;
                    font-size: .2rem;

                    .login-label {
                        width: 2.5rem;
                        line-height: .6rem;
                        font-size: .2rem;
                    }

                    .login-value {
                        width: 5rem;
                        font-size: .2rem;
                        border: none;
                    }
                }
            }
        }
    }
}
</style>